import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import {Toaster} from "react-hot-toast";
import Modal from "react-modal";

function App() {

    Modal.setAppElement('#root');

    return (
      <div className="app">
          <Toaster/>
          <BrowserRouter>

              <header className="app-header">
                  <h1>eScrap by deepblock</h1>
              </header>

              <div className="app-body">
                  <Routes>
                      <Route path="/" element={<Home/>}/>
                  </Routes>
              </div>

              <footer className="app-footer">
                  <p>escrap.deepblock.fr is currently in open beta and usage of the platform may be limited for unpaid
                      users. © 2025 - eScrap by #deepblock</p>
              </footer>
          </BrowserRouter>
      </div>
    );
}

export default App;
