import "./Home.css";
import {useEffect, useState} from "react";
import Select from "react-select";
import Modal from "react-modal";
import {addSearch, getCities, getDepartments} from "../request/request";
import {MagnifyingGlassIcon} from "@radix-ui/react-icons";
import toast from "react-hot-toast";
import SearchTable from "../components/SearchTable/SearchTable";

const Home = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);

  const [allFrance, setAllFrance] = useState(false);
  const [allCities, setAllCities] = useState(false);

  const [query, setQuery] = useState('');
  const [mail, setMail] = useState('');

  useEffect(() => {
    getDepartments().then(data => {
      setDepartments(data)
    }).catch(err => {
      console.error(err);
    });
  }, []);

  useEffect(() => {
    getCities().then(data => {
      setCities(data)
    }).catch(err => {
      console.error(err);
    });
  }, []);

  useEffect(() => {
    selectedCities.forEach(city => {
      if (!selectedDepartments.map(dep => dep.value).includes(city.codeDepartement)) {
        setSelectedCities(selectedCities.filter(c => c.value !== city.value));
      }
    });
  }, [selectedCities, selectedDepartments]);

  const addNewSearch = async () => {

    if (!query) {
      console.error('No query');
      toast.error('Veuillez entrer une recherche');
      return;
    }
    if (!mail) {
      console.error('No mail');
      toast.error('Veuillez entrer un email');
      return;
    }

    let searchCities = [];

    if (allFrance) {
      searchCities = cities.map(city => city.nom);
    } else if (allCities) {
      const citiesList = cities.filter(city => selectedDepartments.map(dep => dep.value).includes(city.codeDepartement))
      searchCities = citiesList.map(city => city.value);
    } else {
      searchCities = selectedCities.map(city => city.value);
    }

    if (searchCities.length === 0) {
      console.error('No cities');
      toast.error('Veuillez sélectionner des villes');
      return;
    }

    const data = {
      query: query,
      mail: mail,
      cities: searchCities.join(','),
    }
    await addSearch(data).then(data => {
      setIsModalOpen(false);
      toast.success('Recherche ajoutée');
    }).catch(err => {
      toast.error('Error: Impossible d\'ajouter la recherche');
      console.error(err);
    });
  }

  const openModal = () => {
    setIsModalOpen(true);
  }

  const checkboxSetAllFrance = (e) => {
    setAllFrance(e.target.checked);
  }

  const checkboxSetAllCities = (e) => {
    setAllCities(e.target.checked);
  }

  const getSearchDetails = async (details) => {
    return details.split(',').map(detail => {
      const [key, value] = detail.split(':');
      return (
        <div key={key} className="search-details">
          <span className="search-details-key">{key}</span>
          <span className="search-details-value">{value}</span>
        </div>
      )
    });
  }

  const departmentSelector = ({departments, onChange}) => {
    const options = departments.map(dep => ({value: dep.code, label: dep.nom}));
    return (
      <Select
        closeMenuOnSelect={false}
        options={options}
        isMulti
        onChange={onChange}
        value={selectedDepartments}
        placeholder="Sélectionnez des départements..."
        className="department-selector"
      />
    );
  };

  const citySelector = ({cities, onChange}) => {
    const departmentsCities = cities.filter(city => selectedDepartments.map(dep => dep.value).includes(city.codeDepartement));
    const options = departmentsCities.map(city => ({
      value: city.nom,
      label: city.nom,
      codeDepartement: city.codeDepartement
    }));

    return (
      <Select
        closeMenuOnSelect={false}
        options={options}
        isMulti
        onChange={onChange}
        value={selectedCities}
        placeholder="Sélectionnez des communes..."
        className="city-selector"
      />
    );
  };

  return (
    <div className="container">

      <SearchTable/>

      <div className="button-new-extraction" onClick={openModal}>
        <div/>
        <div/>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Créer un nouveau scan"
        style={{
          content: {
            width: '50%',
            height: '50%',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflow: 'visible',
          },
        }}
      >
        <div className="modal-header">
          <div className="modal-header-input">
            <MagnifyingGlassIcon className="modal-header-input-icon" width="30px" height="30px"/>
            <input type="text" name="content" placeholder="Effectuez une recherche"
                   onChange={(e) => setQuery(e.target.value)}/>
          </div>
          <button className="modal-header-submit" type="submit" onClick={addNewSearch}>Rechercher</button>
        </div>
        <div className="modal-header-input">
          <MagnifyingGlassIcon className="modal-header-input-icon" width="30px" height="30px"/>
          <input type="mail" name="content" placeholder="Email"
                 onChange={(e) => setMail(e.target.value)}/>
        </div>
        <div className="checkbox">
          <label className="checkbox-france">
            <input type="checkbox" onChange={checkboxSetAllFrance}/>
            Recherche dans la france entière
          </label>
          <label className="checkbox-cities">
            <input type="checkbox" onChange={checkboxSetAllCities}/>
            Recherche dans toutes les villes
          </label>
        </div>
        <div className="modal-body-container">
          <div className="modal-body-title">
            <label>Départements</label>
            <label>Villes</label>

          </div>
          <div className="modal-body-selector">
            {departmentSelector({departments, onChange: setSelectedDepartments})}
            {citySelector({cities, onChange: setSelectedCities})}
          </div>
        </div>
      </Modal>
    </div>

  );
}

export default Home;