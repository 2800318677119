import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const getDepartments = async () => {
    try {
        const response = await axios.get('https://geo.api.gouv.fr/departements');
        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
}

export const getCities = async () => {
    try {
        const response = await axios.get('https://geo.api.gouv.fr/communes');
        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
}

export const addSearch = async (search) => {
    try {
        const response = await axios.post(`${BACKEND_URL}/search`, search, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
}

export const getSearch = async () => {
    try {
        const response = await axios.get(`${BACKEND_URL}/search`);
        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
}

export const downloadFile = async (file) => {
    try {
        const response = await axios.get(`${BACKEND_URL}/search/download/`, {
            params: {
                file: file
            },
            responseType: 'blob'
        });
        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
}

export const deleteSearch = async (id) => {
    try {
        const response = await axios.delete(`${BACKEND_URL}/search`, { data: { id: id } });
        return response.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
}