import "./SearchTable.css";
import {DownloadIcon, TrashIcon} from "@radix-ui/react-icons";
import toast from "react-hot-toast";
import {deleteSearch, downloadFile, getSearch} from "../../request/request";
import {useEffect, useState} from "react";

const SearchTable = () => {

    const [search, setSearch] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);


    useEffect(() => {
        getSearch().then(data => {
            setSearch(data);
        }).catch(err => {
            console.error(err);
        });
    }, []);

    const downloadSearchFile = async (file) => {
        if (!file) {
            console.error('No file');
            toast.error('Error: No file');
            return;
        }
        file = file.replaceAll('\n', '');
        return await downloadFile(file).then(data => {
            if (!data) {
                console.error('No data');
                return;
            }
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', file);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
        }).catch(err => {
            console.error(err);
        });
    }

    const deleteSearchFile = async (id) => {
        if (!id) {
            console.error('No id');
            toast.error('Error: No id');
            return;
        }
        return await deleteSearch(id).then(data => {
            setSearch(search.filter(s => s.id !== id));
            console.log(data);
        }).catch(err => {
            console.error(err);
        });
    }

    const formatElapsedTime = (startTimestamp, endTimestamp) => {
        if (endTimestamp < startTimestamp) {
            return "..."
        }

        const msDifference = Math.abs(endTimestamp - startTimestamp); // Différence en ms
        const minutes = Math.floor(msDifference / (1000 * 60)) % 60; // Minutes restantes
        const hours = Math.floor(msDifference / (1000 * 60 * 60)) % 24; // Heures restantes
        const days = Math.floor(msDifference / (1000 * 60 * 60 * 24)) % 30; // Jours restants
        const months = Math.floor(msDifference / (1000 * 60 * 60 * 24 * 30)); // Mois entiers

        // Créer un tableau des parties non nulles
        const parts = [];

        if (months > 0) parts.push(`${months}mo`);
        if (days > 0) parts.push(`${days}d`);
        if (hours > 0) parts.push(`${hours}h`);
        if (minutes > 0) parts.push(`${minutes}m`);

        // Joindre les parties avec un espace
        return parts.join(" ");
    }


    const data = {nodes: search};

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    if (isMobile) {

        return (
          <>
              {
                  search === undefined || search.length === 0 ?
                    <div className="no-search">Aucune recherche effectuez, veuillez effectuez une recherche</div> :
                    <div className="container-search">
                        <table className="container-table">
                            <thead className="table-thead">
                            <tr className="table-header-row">
                                <th style={{whiteSpace: "nowrap"}} className="table-header-cell header-left-round">Nom
                                </th>
                                <th className="table-header-cell">Temps d'extraction</th>
                                <th style={{whiteSpace: "nowrap"}} className="table-header-cell">Statut</th>
                                <th className="table-header-cell">Téléchargement</th>
                                <th style={{width: "150px"}} className="table-header-cell header-right-round">
                                    <TrashIcon width="21px" height="21px"/>
                                </th>
                            </tr>
                            </thead>
                            <tbody className="table-tbody">
                            {search.map((item) => {
                                return (
                                  <tr key={item.id} className="table-row">
                                      <td className="table-cell cell-left-round">{item.query}</td>
                                      <td className="table-cell">{item.created_at === item.updated_at ?
                                        <div>
                                            <div>
                                                Temps
                                                estimé: {formatElapsedTime(new Date(), new Date(item.time_estimation))}</div>
                                        </div> :
                                        formatElapsedTime(new Date(item.created_at), new Date(item.updated_at))}</td>
                                      <td
                                        className="table-cell">{item.status === 'pending' && item.is_processing ? "processing" : item.status}</td>
                                      <td className="table-cell">
                                          <button className="download-button"
                                                  disabled={item.file_name === null ? true : false}
                                                  onClick={() => downloadSearchFile(item.file_name)}>
                                              <div style={{marginRight: "10px"}}>
                                                  Télécharger
                                              </div>
                                              <DownloadIcon width="18px" height="18px"/>
                                          </button>
                                      </td>
                                      <td className="table-cell cell-right-round">
                                          <button className="suppr-button" onClick={() => deleteSearchFile(item.id)}>
                                              <TrashIcon width="85%" height="85%"/>

                                          </button>
                                      </td>
                                  </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
              }
          </>
        )
    } else {
        return (
          <>
              {
                  search === undefined || search.length === 0 ?
                    <div className="no-search">Aucune recherche effectuez, veuillez effectuez une recherche</div> :
                    <div className="container-search">
                        <table className="container-table">
                            <thead className="table-thead">
                            <tr className="table-header-row">
                                <th style={{whiteSpace: "nowrap"}} className="table-header-cell header-left-round">Nom
                                </th>
                                <th style={{whiteSpace: "nowrap"}} className="table-header-cell">Date</th>
                                <th className="table-header-cell">Temps d'extraction</th>
                                <th className="table-header-cell">Nombres de lignes</th>
                                <th style={{whiteSpace: "nowrap"}} className="table-header-cell">Statut</th>
                                <th style={{whiteSpace: "nowrap"}} className="table-header-cell">Détails</th>
                                <th className="table-header-cell">Téléchargement</th>
                                <th style={{width: "150px"}} className="table-header-cell header-right-round">
                                    <TrashIcon width="21px" height="21px"/>
                                </th>
                            </tr>
                            </thead>
                            <tbody className="table-tbody">
                            {search.map((item) => {
                                return (
                                  <tr key={item.id} className="table-row">
                                      <td className="table-cell cell-left-round">{item.query}</td>
                                      <td className="table-cell">
                                          {new Date(item.updated_at).toLocaleDateString(
                                            'fr-FR',
                                            {
                                                year: 'numeric',
                                                month: '2-digit',
                                                day: '2-digit',
                                            }
                                          )}
                                      </td>
                                      <td className="table-cell">{item.created_at === item.updated_at ?
                                        <div>
                                            <div>
                                                Temps
                                                estimé: {formatElapsedTime(new Date(), new Date(item.time_estimation))}</div>
                                        </div> :
                                        formatElapsedTime(new Date(item.created_at), new Date(item.updated_at))}</td>
                                      <td className="table-cell">{item.line_number}</td>
                                      <td
                                        className="table-cell">{item.status === 'pending' && item.is_processing ? "processing" : item.status}</td>
                                      <td className="table-cell ellipsis">{item.details}</td>
                                      <td className="table-cell">
                                          <button className="download-button"
                                                  disabled={item.file_name === null ? true : false}
                                                  onClick={() => downloadSearchFile(item.file_name)}>
                                              <div style={{marginRight: "10px"}}>
                                                  Télécharger
                                              </div>
                                              <DownloadIcon width="18px" height="18px"/>
                                          </button>
                                      </td>
                                      <td className="table-cell cell-right-round">
                                          <button className="suppr-button" onClick={() => deleteSearchFile(item.id)}>
                                              <TrashIcon width="85%" height="85%"/>

                                          </button>
                                      </td>
                                  </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
              }
          </>
        )
    }
}

export default SearchTable;